import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/styles';

import {  Topbar, Footer } from './components';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { children } = props;
  let history = useHistory();
  const classes = useStyles();

  if (!localStorage.access_token){
    history.push('/sign-in')
  }
  else {
    return (
      <div
        className={clsx({
          [classes.root]: true,
        })}
      >
        <Topbar/>
        <main className={classes.content}>
          {children}
          <Footer />
        </main>
      </div>
    );
  }

  return false;
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
