import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {

  const { className, onSidebarOpen, ...rest} = props;

  const classes = useStyles();

  // const [notifications] = useState([]);

  const handleLogout=()=>{
    props.history.push('/sign-in');
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
  }

  return (
      <AppBar
          {...rest}
          style={{background: localStorage.subdomain === "bpcl" ? "linear-gradient(45deg, #1a73e8 10%, #e5bb2e 30%, #5b2e90 90%)" :
                localStorage.subdomain === "hpcl" ? "linear-gradient(45deg, #172b6e 30%, #c83a27 90%)" :
                    localStorage.subdomain === "iocl" ? "linear-gradient(45deg, #e96c2f 30%, #5b2e90 90%)" :
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"}}
          className={clsx(classes.root, className)}
      >
        <Toolbar>
          <RouterLink to="/">
            <Typography variant="h5" style={{color: '#fff'}}>
              {localStorage.subdomain === "bpcl" ? "REPOS | BPCL" :
                  localStorage.subdomain === "hpcl" ? "REPOS | HPCL" :
                      localStorage.subdomain === "iocl" ? "REPOS | IOCL" :
                          'REPOS | OMC'
              }
            </Typography>
          </RouterLink>
          <div className={classes.flexGrow} />
          <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
          {/*<Hidden mdDown>*/}
            {/*<IconButton color="inherit">*/}
            {/*  <Badge*/}
            {/*      badgeContent={notifications.length}*/}
            {/*      color="primary"*/}
            {/*      variant="dot"*/}
            {/*  >*/}
            {/*    <NotificationsIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton*/}
            {/*    className={classes.signOutButton}*/}
            {/*    color="inherit"*/}
            {/*    onClick={handleLogout}*/}
            {/*>*/}
            {/*  <InputIcon />*/}
            {/*</IconButton>*/}
          {/*</Hidden>*/}
          {/*<Hidden lgUp>*/}
          {/*  <IconButton*/}
          {/*      color="inherit"*/}
          {/*      onClick={onSidebarOpen}*/}
          {/*  >*/}
          {/*    <MenuIcon />*/}
          {/*  </IconButton>*/}
          {/*</Hidden>*/}
        </Toolbar>
      </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  // onSidebarOpen: PropTypes.func
};

export default withRouter(Topbar);
