import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import qs from 'querystring';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};
var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
localStorage.setItem('subdomain', subdomain);


const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
        localStorage.subdomain === "bpcl" ? 'url(/images/bpcl_cover.png)' :
            localStorage.subdomain === "hpcl" ? 'url(/images/repos_omc_hpcl.png)' :
                localStorage.subdomain === "iocl" ? 'url(/images/iocl_cover.png)' : '' ,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.black,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(45),
    color: theme.palette.black
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {

  const { history } = props;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {

    // eslint-disable-next-line
    let mounted = true;

    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleViewPass = () => {
    if (!showPass){
      setShowPass(true)
    }
    else {
      setShowPass(false)
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();

    setIsLoading(true)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      }
    };

    var requestBody={
      password: formState.values.password,
      // client_id:'UjEuHqs7KtzY3JDdGNwML0d57GJHUQZJrDdmUAXN',
      // client_secret:'KAFvzsqUjwvBwYUgmgyvSq7AlkfyFVnamFm5TboUudrAB7Vz7iUPMBXtPqE0u2Anuu0mFlgojM31zifKhUqQ612mSkUugK8bjn7UHljsC1wAJBEUZOXXIf0LvA3Ow6Jq',
      client_id:'WUa0OA7fjhiVrfSrJtvPGGqomTkIMsaszHrF2VIS',
      client_secret:'lbmPThAa6bml1kd2MYLj50hQ96ipExD4B2chpOBudltp20V7Xx8TamkD5am8X3TV5dg5gJbhThJhYE0PzZDRZwQiN69KPhasUOVnZ163YYEQTIdFUyFAfkwlkoRx1f7I',
      is_email:1,
      email: formState.values.email,
      grant_type: "password",
    }

    axios.post('https://drf.reposenergy.com/api/web/v1/users-login/', qs.stringify(requestBody), config)
        .then(response=>{
          setIsLoading(false)
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('access_token', response.data.access_token);
          history.push('/users');
        }).catch(error=> {
      console.log(error);
      alert("Invalid Email/ Password. Please Retry");
      setIsLoading(false)
    })

  };

  const hasError = field =>
      formState.touched[field] && formState.errors[field] ? true : false;


  if (localStorage.access_token){
    history.push('/users')
  }
  else {
    return (
        <div className={classes.root}>
          <Grid
              className={classes.grid}
              container
          >
            <Grid
                className={classes.quoteContainer}
                item
                lg={5}
            >
              <div className={classes.quote}>
                <div className={classes.quoteInner}>
                  <div className={classes.person}>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
                className={classes.content}
                item
                lg={7}
                xs={12}
            >
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  <form
                      className={classes.form}
                      onSubmit={handleSignIn}
                  >
                    <Typography
                        className={classes.title}
                        variant="h3"
                    >
                      Sign in
                    </Typography>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                    >
                      Sign in with you credentials
                    </Typography>
                    <TextField
                        className={classes.textField}
                        error={hasError('email')}
                        fullWidth
                        helperText={
                          hasError('email') ? formState.errors.email[0] : null
                        }
                        label="Email address"
                        name="email"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.email || ''}
                        variant="outlined"
                    />
                    <div>
                      <TextField
                          className={classes.textField}
                          error={hasError('password')}
                          fullWidth
                          helperText={
                            hasError('password') ? formState.errors.password[0] : null
                          }
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          type={showPass ? "text" : "password"}
                          value={formState.values.password || ''}
                          variant="outlined"
                      >
                      </TextField>
                      <Link href="#">
                        <Typography
                            color="textSecondary"
                            variant={"overline"}
                            gutterBottom
                            style={{float: 'right'}}
                            onClick={handleViewPass}
                        >
                          {showPass ? "Hide Password" : "View Password"}
                        </Typography>
                      </Link>
                    </div>

                    {
                      isLoading ?
                          <div style={{textAlign: 'center', marginTop: '15px'}}>
                            <CircularProgress/>
                          </div>:
                          <Button
                              className={classes.signInButton}
                              color="primary"
                              disabled={!formState.isValid}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                          >
                            Sign in now
                          </Button>
                    }
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
    );
  }
  return false;
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
