import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {}
  ,content: {
    padding: 0
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {

  const { className, data, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
      <Card
          {...rest}
          className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection="desc">
                      {/*<Tooltip*/}
                      {/*    enterDelay={300}*/}
                      {/*    title="Sort"*/}
                      {/*>*/}
                        {/*<TableSortLabel*/}
                        {/*    active*/}
                        {/*    direction="desc"*/}
                        {/*>*/}
                        {/*</TableSortLabel>*/}
                      {/*</Tooltip>*/}
                      Retailer
                    </TableCell>
                    <TableCell style={{width: '120px'}}>Address</TableCell>
                    <TableCell>Capacity</TableCell>
                    <TableCell>License</TableCell>
                    <TableCell style={{width: '120px'}}>Flow Meter</TableCell>
                    <TableCell>Automation</TableCell>
                    <TableCell style={{textAlign: 'left'}}>
                      <Typography
                          gutterBottom
                          align={"left"}
                          style={{
                            marginLeft: 120
                          }}
                          // variant="h6"
                      >
                        Volume
                      </Typography>
                      <TableRow>
                        <TableCell style={{borderBottom: 'none'}}>Today</TableCell>
                        <TableCell style={{borderBottom: 'none'}}>Weekly</TableCell>
                        <TableCell style={{borderBottom: 'none'}}>Monthly</TableCell>
                        <TableCell style={{borderBottom: 'none'}}>Yearly</TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell>Totalizer</TableCell>
                    <TableCell>SOD Reading</TableCell>
                    {/*<TableCell>Day Start Dispense at</TableCell>*/}
                    <TableCell>EOD Reading</TableCell>
                    {/*<TableCell>Day End Dispense at</TableCell>*/}
                    <TableCell>Last Updated at</TableCell>
                  </TableRow>
                </TableHead>
                {
                  props.unauthorized ?
                      <TableBody>
                        <TableRow style={{textAlign: 'center'}}>
                          <TableCell colSpan={7} style={{width: '100%', textAlign: 'center'}}>
                            Oops! Your are not Authorized
                          </TableCell>
                        </TableRow>
                      </TableBody> :
                      <TableBody>
                        {
                          data === null ?
                              <TableRow style={{textAlign: 'center'}}>
                                <TableCell colSpan={7} style={{width: '100%', textAlign: 'center'}}>
                                  <CircularProgress/>
                                </TableCell>
                              </TableRow> :
                              data.map(user=>(
                                  <TableRow>
                                    <TableCell>{user.retailOutletName}</TableCell>
                                    <TableCell style={{width: '120px'}}>{user.pumpAddress}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>{user.rppData.capacity}</TableCell>
                                    <TableCell>{user.rppData.registrationNumber}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>{user.rppData.dispensingDevice}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>{user.rppData.automationType}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>
                                      <TableRow>
                                        <TableCell style={{width: '80px', borderBottom: 'none'}}>
                                          {user.todayVolume}
                                        </TableCell>
                                        <TableCell style={{width: '80px', borderBottom: 'none'}}>
                                          {user.weeklyVolume}
                                        </TableCell>
                                        <TableCell style={{width: '80px', borderBottom: 'none'}}>
                                          {user.monthlyVolume}
                                        </TableCell>
                                        <TableCell style={{width: '80px', borderBottom: 'none'}}>
                                          {user.yearlyVolume}
                                        </TableCell>
                                      </TableRow>
                                    </TableCell>
                                    <TableCell style={{fontWeight: 600}}>
                                      {user.lifetimeVolume}
                                    </TableCell>
                                    <TableCell style={{textAlign: 'center'}}>
                                      {user.dayStartReading}
                                    </TableCell>
                                    {/*<TableCell style={{textAlign: 'center'}}>*/}
                                    {/*  {user.dayStartDispenseTime}*/}
                                    {/*</TableCell>*/}
                                    <TableCell style={{textAlign: 'center'}}>
                                      {user.dayEndReading}
                                    </TableCell>
                                    {/*<TableCell style={{textAlign: 'center'}}>*/}
                                    {/*  {user.dayEndDispenseTime}*/}
                                    {/*</TableCell>*/}

                                    <TableCell style={{textAlign: 'center'}}>
                                      {
                                        moment(user.volumeUpdatedAt).subtract(5.5, 'hours').format('LLLL')
                                      }
                                    </TableCell>
                                  </TableRow>
                              ))
                        }
                      </TableBody>
                }
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        {/*<CardActions className={classes.actions}>*/}
        {/*  <TablePagination*/}
        {/*    component="div"*/}
        {/*    count={data === null ? '' : data.length}*/}
        {/*    onChangePage={handlePageChange}*/}
        {/*    onChangeRowsPerPage={handleRowsPerPageChange}*/}
        {/*    page={page}*/}
        {/*    rowsPerPage={rowsPerPage}*/}
        {/*    rowsPerPageOptions={[5, 10, 25]}*/}
        {/*  />*/}
        {/*</CardActions>*/}
      </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default UsersTable;
