import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Typography} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [myStateArr, setMyStateArr] = useState(null);
  const [dataReplica, setDataReplica] = useState(null);
  const [auth, setUnauthorizedAccess] = useState(null);

  var subdomain = localStorage.getItem('subdomain').toUpperCase()

  let requestBody = {
    omc: subdomain === "FALSE" ? ['BPCL'] : [subdomain]
  };

  const setDefaultStates = (stateArr) => {
    requestBody.state = stateArr;
    setMyStateArr(stateArr);
    fetchData();
  }

  const onStateChanged= (state)=>{
    setMyStateArr([state]);
    if (state!==null){
      if (typeof (state) === "string"){
        requestBody = {
          state: [state],
          omc: subdomain === "FALSE" ? ['BPCL'] : [subdomain]
        };
        fetchData();
      }
      else {
        requestBody = {
          state: state,
          omc: subdomain === "FALSE" ? ['BPCL'] : [subdomain]
        };
        fetchData();
      }

    }
    else {
      fetchData();
    }
  };

  const onZoneChanged= (region, state)=>{
    if (region!==null){
      if(typeof (region) === "object"){
        requestBody = {
          // region: region,
          state: state,
          omc: subdomain === "FALSE" ? ['BPCL'] : [subdomain]
        };
      }
      else {
        requestBody = {
          region: [region],
          omc: subdomain === "FALSE" ? ['BPCL'] : [subdomain]
        };
      }
      fetchData();
    }
    else {
      fetchData();
    }
  };

  const onSortOptions = (sortValue)=>{

    if (sortValue!==null){
      requestBody.sort_by=sortValue;
      requestBody.state= myStateArr
      fetchData();
    }
    else {
      fetchData();
    }
  }

  const onSearchTerm = (searchQuery) =>{
    var i;
    var newData = [];

    if (searchQuery.length > 3){
      for(i=0;i<data.length;i++){
        if (data[i].retailOutletName){

          let retail_outlet = data[i].retailOutletName.toLowerCase().includes(searchQuery.toLowerCase());
          let registration_number = data[i].rppData.registrationNumber.toLowerCase().includes(searchQuery.toLowerCase());

          if(retail_outlet ||  registration_number){
            newData.push(data[i]);
          }
        }
      }
      setData(newData);
    }
    else {
      setData(dataReplica);
    }
  }

  let mounted = true;

  const fetchData = () => {
    axios.post('https://reports.reposenergy.com/v1/refueler/list/', requestBody, {
      headers:{
        Authorization: 'Bearer ' + localStorage.access_token,
      }
    }).then(res => {
      if (mounted) {
        setData(res.data.data.refuelers);
        setDataReplica(res.data.data.refuelers);
        setUnauthorizedAccess(false);
      }
    }).catch(error=> {
      if(error.response.status === 422){
        setUnauthorizedAccess(true);
      }
      if(error.response.status === 401){
        console.log(error.response.status);
      }
    })
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      mounted = false;
    };

  }, []);

  return (
      <div className={classes.root}>
        <UsersToolbar
            onStateChangeFilter={onStateChanged}
            onZoneChangeFilter={onZoneChanged}
            onSort={onSortOptions}
            onSearchTerm={onSearchTerm}
            statesArray={setDefaultStates}
            data={data}
        />
        <div className={classes.content}>
          <div>
            <Typography
                align="left"
                variant={"h6"}
                style={{marginBottom : '20px'}}
            >
              {data !== null && "Found " + data.length} Repos Petrol Pumps
            </Typography>
            <UsersTable data={data} unauthorized={auth}/>
          </div>
        </div>
      </div>
  );
};

export default withRouter(UserList);

