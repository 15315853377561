import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import { SearchInput } from 'components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import axios from 'axios';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import Hidden from "@material-ui/core/Hidden";
import { CSVLink } from "react-csv";
import moment from "moment";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const UsersToolbar = props => {
  const { className, ...rest } = props;

  const { history } = props;

  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [zone, setZone] = React.useState([]);
  const [province, setProvince] = React.useState([]);
  const [provinceArray, setProvinceArray] = React.useState([]);
  const [responseData, setResponseData] = React.useState([]);
  const [selectedZone, setSelectedZone] = React.useState(null);
  const [selectedProvince, setSelectedProvince] = React.useState(null);
  const [showFilters, setShowFilters] = React.useState(false);

  const onZoneChange =(event, values)=>{
    setSelectedZone(values)
    setShowFilters(true)
  }

  const onStateChange =(event)=>{
    setSelectedProvince(event.target.value)
    setShowFilters(true)
  }

  const applyFilters =()=>{
    if (selectedZone !== null){
      props.onZoneChangeFilter(selectedZone);
      setState(false)
      toggleDrawer('right', false)
    }
    if (!selectedProvince !== null){
      props.onStateChangeFilter(selectedProvince);
      setState(false)
      toggleDrawer('right', false)
    }
  }

  const clearFilters = (event, value)=>{
    if (selectedZone !== null){
      setSelectedZone(null)
      onZoneChange(event , null)
      props.onZoneChangeFilter(zone, province);
    }
    if (!selectedProvince !== null){
      setSelectedProvince(null)
      onStateChange(event , null)
      props.onStateChangeFilter(provinceArray);
    }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const getSearchTerm= (event) => {
    event.preventDefault();
    props.onSearchTerm(event.target.value)
  }

  const headers = [
    { label: "Retailer", key: "retailOutletName" },
    { label: "Address", key: "pumpAddress" },
    { label: "Capacity", key: "rppData.capacity" },
    { label: "License", key: "rppData.registrationNumber" },
    { label: "Flow Meter Type", key: "rppData.dispensingDevice" },
    { label: "Automation", key: "rppData.dispensingDevice" },
    { label: "Today's Volume", key: "todayVolume" },
    { label: "Weekly Volume", key: "weeklyVolume" },
    { label: "Monthly Volume", key: "monthlyVolume" },
    { label: "Yearly Volume", key: "yearlyVolume" },
    { label: "Last Updated At", key: 'volumeUpdatedAt' },
    { label: "Day Start Reading", key: 'dayStartReading' },
    { label: "Day End Reading", key: 'dayEndReading' },
    { label: "Total", key: 'lifetimeVolume' },
  ];

  // select controls
  const options = [
    'SORT',
    'Todays Volume',
    'Weekly Volume',
    'Monthly Volume',
    'Yearly Volume',
    'Lifetime Volume',
    'Retail-Outlet Name(Z to A)',
    'Retail-Outlet Name(A to Z)',
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    props.onSort(index)
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let arrayOfStates;

  useEffect(() => {
    let mounted = true;

    let requestBody={};

    const fetchData = () => {

      axios.post('https://drf.reposenergy.com/api/web/v1/iam/get_allowed_filters_for_group', requestBody, {
        headers:{
          Authorization: 'Bearer ' + localStorage.access_token,
        }
      }).then(res => {
        if (mounted) {
          setZone(res.data.data.region);
          var i;
          var stateArr=[];
          for (i=0;i<res.data.data.state.length;i++){
            stateArr.push(res.data.data.state[i].value)
          }
          props.statesArray(stateArr)
          setProvinceArray(stateArr)
          setProvince(res.data.data.state);
          setResponseData(res.data.data)
        }
      }).catch(error=> {
        if(error.response.status === 401){
          history.push('/sign-in')
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
        }
      })
    };

    fetchData();
    return () => {
      mounted = false;
    };

  }, []);

  return (
      <div
          {...rest}
          className={clsx(classes.root, className)}
      >
        <div className={classes.row}>
          <SearchInput
              className={classes.searchInput}
              placeholder="Search by Retailer"
              onChange={getSearchTerm}
          />
          <span className={classes.spacer} />
          <Hidden mdDown>
            <div style={{
              backgroundImage:
                  'url(/repos_petrol_pump.png)',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              width: 250,
              height: 70,
              backgroundSize: 470
            }}>

            </div>
          </Hidden>
          <List style={{
            background: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            borderRadius: '5px',
            marginRight: '5px',
            boxShadow:'0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)'
          }}
                component="nav" aria-label="Device settings">
            <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                // aria-label="when device is locked"
                onClick={handleClickListItem}
            >
              <SortIcon /> <ListItemText style={{marginLeft: '5px', }} primary={options[selectedIndex]}  />
            </ListItem>
          </List>
          <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
          >
            {options.map((option, index) => (
                <MenuItem
                    key={option}
                    // disabled={index === 0}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
            ))}
          </Menu>

          <Button
              variant="contained"
              color="default"
              className={classes.button}
              style={{height : '65px'}}
              startIcon={<FilterIcon />}
              onClick={toggleDrawer('right', true)}
          >
            Filter
          </Button>
          {
            props.data &&
            <CSVLink data={props.data} headers={headers}
                     style={{
                       background: '#fff',
                       color: 'rgba(0, 0, 0, 0.87)',
                       borderRadius: '5px',
                       marginRight: '5px',
                       boxShadow:'0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
                       height: 65,
                       fontFamily: 'Hind Siliguri,sans-serif',
                       margin: 5,
                       textAlign: 'center',
                       padding: 20,
                     }}
            >
              Export to CSV
            </CSVLink>
          }
          <SwipeableDrawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
            <div style={{margin: 10, padding: 10}}>
              {
                !responseData.region ? '' :
                    <Autocomplete
                        id="country-select-demo"
                        style={{ width: 300, margin: 10 }}
                        options={zone}
                        onChange={onZoneChange}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        value={selectedZone}
                        getOptionLabel={(option) => option}
                        renderOption={(option) => (
                            <React.Fragment>
                              {option}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Choose a Region"
                                variant="outlined"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
              }
              {/*{*/}
              {/*  !responseData.state ? '' :*/}
              {/*      <Autocomplete*/}
              {/*          id="country-select-demo"*/}
              {/*          style={{ width: 300, margin: 10 }}*/}
              {/*          options={province}*/}
              {/*          onChange={onStateChange}*/}
              {/*          classes={{*/}
              {/*            option: classes.option,*/}
              {/*          }}*/}
              {/*          autoHighlight*/}
              {/*          value={selectedProvince}*/}
              {/*          getOptionLabel={(option) => option}*/}
              {/*          renderOption={(option) => (*/}
              {/*              <React.Fragment>*/}
              {/*                {option}*/}
              {/*              </React.Fragment>*/}
              {/*          )}*/}
              {/*          renderInput={(params) => (*/}
              {/*              <TextField*/}
              {/*                  {...params}*/}
              {/*                  label="Choose a State"*/}
              {/*                  variant="outlined"*/}
              {/*                  inputProps={{*/}
              {/*                    ...params.inputProps,*/}
              {/*                    autoComplete: 'new-password', // disable autocomplete and autofill*/}
              {/*                  }}*/}
              {/*              />*/}
              {/*          )}*/}
              {/*      />*/}
              {/*}*/}
              {
                !responseData.state ? '' :
                    <FormControl variant="outlined" style={{width: '95%', margin: 10}}>
                      <InputLabel id="demo-simple-select-outlined-label">Select State</InputLabel>
                      <Select
                          labelId="select-province"
                          id="select-province"
                          value={selectedProvince}
                          onChange={onStateChange}
                          label="Age"
                      >
                        {
                         province.map(singleState=>
                           (<MenuItem value={singleState.value}>{singleState.label}</MenuItem>)
                         )
                        }
                      </Select>
                    </FormControl>
              }

              {
                showFilters &&
                <div>
                  <Button
                      color={'primary'}
                      fullWidth
                      variant={'contained'}
                      onClick={applyFilters}
                  >
                    Apply
                  </Button>
                  <Button
                      color={'primary'}
                      fullWidth
                      variant={'contained'}
                      style={{ marginTop: 10 }}
                      onClick={clearFilters}
                  >
                    Clear Filters
                  </Button>
                </div>
              }

              <Button
                  fullWidth
                  onClick={toggleDrawer('right', false)}
              >
                Cancel
              </Button>
            </div>
          </SwipeableDrawer>
        </div>
        {/*<div className={classes.row}>*/}
        {/*  <SearchInput*/}
        {/*      className={classes.searchInput}*/}
        {/*      placeholder="Search"*/}
        {/*      onChange={getSearchTerm}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default withRouter(UsersToolbar);
